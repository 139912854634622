<template>
    <div>
        <img src="@/assets/icons/img-alt.svg" class="mx-auto" />
        <div class="text-center text-primary font-bold text-xl mt-4">{{ title }}</div>
        <div class="text-center text-gray-500 font-medium">{{ message }}</div>
    </div>
</template>

<script>
    export default {
        name: 'NotFoundCard',

        props: {
            title: {
                type: String,
                default: 'No results found'
            },
            message: {
                type: String,
                default: "we couldn't find the resource you were looking for"
            }
        }
    };
</script>
